export default () => ({

    appUrl: "http://localhost:3000/",
    apiUrl: "http://3.210.156.236/api/v1/",

    appUrl: "http://localhost:3000/",

    apiUrl: "https://delidel-67995-ruby.b67995.dev.us-east-1.aws.svc.builder.cafe/api/v1",
    //appUrl: "https://delidel-67995-ruby.b67995.dev.us-east-1.aws.svc.builder.cafe/",
    currency: "SAR"

});